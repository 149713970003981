$(document).ready(function () {
    var bvpixel = $('.bvdata').data;
    window.bvCallback = function (BV) {
        BV.pixel.trackTransaction(bvpixel);
    };
});


$(document).ready(function () {    
    var  $bvType;
    if ($('.page').attr('data-querystring') === "cid=where-to-buy") {
        $bvType = "cid=where-to-buy"
    } else {
        $bvType = $('.page').attr('data-action');
    }
    var  $bvLabel = $('.storepage').attr('id');
    window.bvCallback = function (BV) {
        BV.pixel.trackConversion({
            "type" : $bvType,
            "label" : $bvLabel,
        });
    };
});
